/* eslint-disable boundaries/element-types */
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'

import {
  selectReservationTables,
  selectSelectedMaxPersonsCount
} from '@/entities/create-update-reservation'
import { MapModeEnum, setMapMode } from '@/entities/map'
import { useConfirmReservationRequestMutation } from '@/entities/reservation-request'
import { selectSelectedAddressId } from '@/entities/session'
import { Reservation } from '@/features/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  VaulFooter
} from '@/shared/ui'
import { MobileMapHalls } from '@/widgets/map'

import css from './RequestPickTableStep.module.css'

type RequestPickTableStepProps = {
  setStep: Dispatch<SetStateAction<number>>
  reservationReqId: number
  onComplete?: () => void
}

export default function RequestPickTableStep({
  setStep,
  reservationReqId,
  onComplete = undefined
}: RequestPickTableStepProps) {
  const addressId = useAppSelector(selectSelectedAddressId)

  const dispatch = useAppDispatch()
  const selectedTables = useAppSelector(selectReservationTables)
  const selectedMaxPersonsCount = useAppSelector(selectSelectedMaxPersonsCount)

  const [open, setOpen] = useState(false)

  const [confirmReservation, { isLoading, isSuccess }] =
    useConfirmReservationRequestMutation()

  const isNextStepAvailable = selectedTables.length > 0

  useEffect(() => {
    dispatch(setMapMode(MapModeEnum.many))

    return () => {
      dispatch(setMapMode(MapModeEnum.one))
    }
  }, [])

  const nextStepHandler = () => {
    if (!addressId || !selectedTables.length) return

    confirmReservation({
      addressId,
      reservationReqId,
      body: {
        places: selectedTables.map((table) => table.id)
      }
    })
  }

  useEffect(() => {
    if (isSuccess) {
      if (onComplete) onComplete()
      else setStep(2)
    }
  }, [isSuccess])

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  return (
    <section className={css.map}>
      <Reservation.TablePicker />
      <VaulFooter className={css.map__footer}>
        <AlertDialog open={open}>
          <AlertDialogTrigger asChild>
            <Button
              size="xs"
              className={css.map__button}
              contentClassName={css.map__buttonContent}
              variant={isNextStepAvailable ? 'primary' : 'secondary'}
              disabled={!isNextStepAvailable || isLoading}
              isLoading={isLoading}
              onClick={
                // eslint-disable-next-line yoda
                1 <= selectedMaxPersonsCount
                  ? nextStepHandler
                  : toggleDialogHandler
              }
            >
              {!isNextStepAvailable
                ? 'Выберите любой столик'
                : 'Подтвердить бронь'}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Количество гостей превышает количество мест
              </AlertDialogTitle>
              <AlertDialogDescription>
                Вы&nbsp;можете продолжить бронирование или вернуться назад
                и&nbsp;добавить дополнительный стол
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction asChild>
                <Button onClick={nextStepHandler}>Продолжить</Button>
              </AlertDialogAction>
              <AlertDialogCancel onClick={toggleDialogHandler}>
                Отмена
              </AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <MobileMapHalls />
      </VaulFooter>
    </section>
  )
}
