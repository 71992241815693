/* eslint-disable boundaries/element-types */
import { memo, useEffect } from 'react'

import { cx } from 'class-variance-authority'
import { isAfter, isBefore, sub } from 'date-fns'

import { config } from '@/app/config'
import {
  selectMapDatetime,
  selectTables as setSelectTables
} from '@/entities/map'
import { ReservationCard } from '@/entities/reservation'
import {
  selectTableWithOpenTimeline,
  setTableWithOpenTimeline
} from '@/entities/timeline'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Vaul, VaulBody, VaulContent, VaulTrigger } from '@/shared/ui'
import { ReservationVaulContent } from '@/widgets/reservation'
import { TimelineWidget } from '@/widgets/Timeline'

import { EmptyTable } from './EmptyTable'

import css from './TableCardContent.module.css'

const { MIN_RESERVATION_TIME_MIN } = config

type TableCardContentProps = {
  tableWSData: Table[]
}

export const TableCardContent = memo(
  ({ tableWSData }: TableCardContentProps) => {
    const dispatch = useAppDispatch()
    const mapItem = useAppSelector(selectTableWithOpenTimeline)
    const curDatetime = useAppSelector(selectMapDatetime)

    const currentTable = tableWSData?.find((t) => t.id === mapItem?.id)

    const currentReservation = currentTable?.reservations.find(
      (res) =>
        isAfter(
          curDatetime,
          sub(res.start_date, { minutes: MIN_RESERVATION_TIME_MIN })
        ) && isBefore(curDatetime, res.end_date)
    )

    const isReservationNow = Boolean(currentReservation)

    const nextReservationTime = currentTable?.reservations.find((res) =>
      isAfter(res.start_date, curDatetime)
    )?.start_date

    useEffect(() => {
      if (!mapItem) {
        dispatch(setSelectTables([]))
      }
    }, [mapItem])

    return (
      <VaulBody
        className={cx(css.body, { [css.body_empty]: !isReservationNow })}
      >
        <TimelineWidget
          selectedTables={currentTable && [currentTable]}
          isShort
          showEmptySuggestion
          mapItem={mapItem}
        />
        <div className={css.content}>
          {isReservationNow && currentReservation && (
            <Vaul>
              <VaulTrigger asChild>
                <ReservationCard
                  id={currentReservation.id}
                  guest={currentReservation.guest}
                  personsCount={currentReservation.persons_count}
                  startDate={currentReservation.start_date}
                  mainTableNumber={currentReservation.main_table_number}
                  source={currentReservation.source}
                  link={currentReservation.link}
                  tags={currentReservation.tags}
                  status={currentReservation.status}
                  endDate={currentReservation.end_date}
                />
              </VaulTrigger>
              <VaulContent fullScreen>
                <ReservationVaulContent reservationId={currentReservation.id} />
              </VaulContent>
            </Vaul>
          )}
          {!isReservationNow && (
            <EmptyTable
              table={mapItem!}
              setOpen={() => dispatch(setTableWithOpenTimeline())}
              nextReservationTime={nextReservationTime}
            />
          )}
        </div>
      </VaulBody>
    )
  }
)
