/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from 'react'

import { createBrowserRouter, Outlet } from 'react-router-dom'

import { PATHS } from '@/shared/lib'
import { MobileGuestsFooter } from '@/widgets/guests'
import { MobileMapFooter, MobileMapHeader } from '@/widgets/map'
import {
  MobileReservationsFooter,
  MobileReservationsHeader
} from '@/widgets/reservations'

import { AuthGuard, GuestGuard, MobileGuard } from './guards'
import { DesktopLayout, MobileLayout } from './layouts'
import { ProfileProvider } from './providers'
import { RootRedirect } from './rootRedirect'

const MobileReservationPage = lazy(() => import('@/pages/mobile/reservation'))
const MobileRequestConfirmPage = lazy(
  () => import('@/pages/mobile/request-confirm')
)
const MobileGuestPage = lazy(() => import('@/pages/mobile/guest'))
const MobileMapPage = lazy(() => import('@/pages/mobile/map'))
const MobileGuestsPage = lazy(() => import('@/pages/mobile/guests'))
const MobileReservationsPage = lazy(() => import('@/pages/mobile/reservations'))
const MobileProfilePage = lazy(() => import('@/pages/mobile/profile'))

const LoginPage = lazy(() => import('@/pages/login'))
const SignUpPage = lazy(() => import('@/pages/sign-up'))
const ReservationsPage = lazy(() => import('@/pages/reservations'))
const ReservationsArchivePage = lazy(
  () => import('@/pages/reservations-archive')
)
const NewReservationPage = lazy(() => import('@/pages/new-reservation'))
const GuestsPage = lazy(() => import('@/pages/guests'))
const GuestPage = lazy(() => import('@/pages/guest'))
const MapPage = lazy(() => import('@/pages/map'))
const ReservationPage = lazy(() => import('@/pages/reservation'))
const TimelinePage = lazy(() => import('@/pages/timeline'))
const WaitingListPage = lazy(() => import('@/pages/waiting-list'))
const ReservationRequestConfirm = lazy(() => import('@/pages/request-confirm'))

const NotFoundPage = lazy(() => import('@/pages/not-found'))

export const desktopRouter = createBrowserRouter([
  {
    children: [
      {
        path: PATHS.login,
        element: (
          <AuthGuard>
            <LoginPage />
          </AuthGuard>
        )
      },
      {
        path: PATHS.signUp,
        element: (
          <AuthGuard>
            <SignUpPage />
          </AuthGuard>
        )
      }
    ],
    errorElement: <div>Error</div>
  },
  {
    element: (
      <GuestGuard>
        <ProfileProvider>
          <DesktopLayout />
        </ProfileProvider>
      </GuestGuard>
    ),
    errorElement: <div>Error</div>,
    children: [
      {
        path: '/',
        element: <RootRedirect />
      },
      {
        path: PATHS.guests,
        element: <GuestsPage />
      },
      {
        path: `${PATHS.guest}/:id`,
        element: <GuestPage />
      },
      {
        path: PATHS.reservations,
        element: <ReservationsPage />
      },
      {
        path: `${PATHS.reservations}${PATHS.archive}`,
        element: <ReservationsArchivePage />
      },
      {
        path: PATHS.timeline,
        element: (
          <MobileGuard>
            <TimelinePage />
          </MobileGuard>
        )
      },
      {
        path: PATHS.map,
        element: <MapPage />
      },
      {
        path: PATHS.waitingList,
        element: <WaitingListPage />
      },
      {
        path: `${PATHS.reservation}/:id`,
        element: <ReservationPage />
      },
      {
        path: PATHS.newReservation,
        element: <NewReservationPage />
      },
      {
        path: `${PATHS.requests}/:reservationReqId`,
        element: <ReservationRequestConfirm />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
])

export const mobileRouter = createBrowserRouter([
  {
    children: [
      {
        path: PATHS.login,
        element: (
          <AuthGuard>
            <LoginPage />
          </AuthGuard>
        )
      },
      {
        path: PATHS.signUp,
        element: (
          <AuthGuard>
            <SignUpPage />
          </AuthGuard>
        )
      }
    ]
  },
  {
    element: (
      <GuestGuard>
        <ProfileProvider>
          <Outlet />
        </ProfileProvider>
      </GuestGuard>
    ),
    children: [
      {
        element: (
          <MobileLayout
            withMenu
            headerSlot={<MobileMapHeader />}
            footerSlot={<MobileMapFooter />}
          />
        ),
        children: [
          {
            path: '/',
            element: <RootRedirect />
          },
          {
            path: PATHS.map,
            element: <MobileMapPage />
          }
        ]
      },
      {
        element: <MobileLayout withMenu footerSlot={<MobileGuestsFooter />} />,
        children: [
          {
            path: PATHS.guests,
            element: <MobileGuestsPage />
          }
        ]
      },
      {
        element: <MobileLayout withMenu />,
        children: [
          {
            path: PATHS.profile,
            element: <MobileProfilePage />
          }
        ]
      },
      {
        element: (
          <MobileLayout
            withMenu
            headerSlot={<MobileReservationsHeader />}
            footerSlot={<MobileReservationsFooter />}
          />
        ),
        children: [
          {
            path: PATHS.reservations,
            element: <MobileReservationsPage />
          }
        ]
      },
      {
        element: <MobileLayout />,
        children: [
          {
            path: `${PATHS.reservation}/:id`,
            element: <MobileReservationPage />
          },
          {
            path: `${PATHS.guest}/:id`,
            element: <MobileGuestPage />
          },
          {
            path: `${PATHS.requests}/:id`,
            element: <MobileRequestConfirmPage />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
      }
    ]
  }
])
