/* eslint-disable boundaries/element-types */
import {
  type ComponentPropsWithoutRef,
  type MouseEventHandler,
  useEffect,
  useRef,
  useState
} from 'react'

import { cx } from 'class-variance-authority'
import { isDesktop, isMobileOnly } from 'react-device-detect'
import {
  type ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch'

import { selectReservationTableIds } from '@/entities/create-update-reservation'
import { resetAvailableParams } from '@/entities/take-table'
import { useCheckClick, useClock } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { InfoBox } from '@/shared/ui'

import { getUniqueDepositsWithColors } from '../../lib'
import { MapModeEnum } from '../../model/mapModes'
import { selectMapMode, selectTimeSliceTables } from '../../model/selectors'
import { selectTable, unselectTable } from '../../model/slice'
import { getSliceOfTime } from '../../model/thunk'
import { MapItem } from '../../ui/MapItem'
import { TablesInfo } from '../TablesInfo'

import css from './Map.module.css'

const BOARD_SIZE = {
  width: 1100,
  height: 1100
}

type MapProps = ComponentPropsWithoutRef<'section'> & {
  mapIdx?: number
  selectedTableId?: number
  tables: ConfItem[] | undefined
  reservations?: TransformedNewReservations
  sliceStartDate?: Date
  sliceEndDate?: Date | null
  zoomTo?: string
  clickableDisabled?: boolean
  isNoInfo?: boolean
  onlyView?: boolean
  isScaled?: boolean
  isPickTable?: boolean
  initialScale?: number
  minScale?: number
  withDepositsInfo?: boolean
  withComingSoon?: boolean
  withRecentlyCompleted?: boolean
  renderTransferTablePopup?: (props: {
    clickHandler: MouseEventHandler
    renderTable: () => JSX.Element
    renderTableClickable: (isCurrentReservation: boolean) => JSX.Element
    tableId: number
  }) => JSX.Element
}

export function Map({
  mapIdx,
  selectedTableId,
  tables = [],
  reservations,
  sliceStartDate,
  sliceEndDate,
  zoomTo,
  className,
  clickableDisabled = true,
  isNoInfo = false,
  onlyView = false,
  isScaled = true,
  isPickTable = false,
  withDepositsInfo = false,
  withComingSoon = true,
  withRecentlyCompleted = false,
  initialScale = 0.6,
  minScale = 0.5,
  renderTransferTablePopup
}: MapProps) {
  const dispatch = useAppDispatch()
  const timeSliceTables = useAppSelector(selectTimeSliceTables)
  const mapMode = useAppSelector(selectMapMode)
  const selectedTableIds = useAppSelector(selectReservationTableIds)

  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)

  const date = useClock()
  const [init, setInit] = useState(false)
  const [scale, setScale] = useState(initialScale)
  const [tablesElements, setTablesElements] = useState<HTMLDivElement[]>([])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (zoomTo && transformComponentRef.current) {
        const { zoomToElement } = transformComponentRef.current
        zoomToElement(zoomTo, 0.7, 300, 'easeOutQuad')
      }
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [tables, selectedTableId])

  useEffect(() => {
    const selectedTable = tables?.find(
      (table) => table.map_item.id === selectedTableIds[0]
    )

    if (selectedTable) dispatch(selectTable(selectedTable.map_item))
  }, [selectedTableIds, tables])

  useEffect(() => {
    if (reservations) {
      dispatch(
        getSliceOfTime({
          sliceDate: sliceStartDate ?? date,
          reservations,
          sliceEndDate: sliceEndDate ?? undefined
        })
      )
    }
  }, [reservations, date, sliceStartDate, sliceEndDate])

  const handleInit = (ref: ReactZoomPanPinchRef) => {
    ref.centerView(undefined, 0)
    setInit(true)
  }

  const unselectTableHandler = () => {
    if (mapMode === MapModeEnum.one || mapMode === MapModeEnum.change) {
      dispatch(unselectTable())
      dispatch(resetAvailableParams())
    }
  }

  const {
    ref,
    handlers: [handleMouseDown, handleMouseUp]
  } = useCheckClick(unselectTableHandler)

  if (tables && tables.length === 0) {
    return <InfoBox>В данном зале пока нет столов</InfoBox>
  }

  const deposits = getUniqueDepositsWithColors(tables)

  return (
    <section className={cx(css.map, className)}>
      {deposits.length > 0 && withDepositsInfo && (
        <TablesInfo deposits={deposits} />
      )}
      <TransformWrapper
        onZoom={(ref) => setScale(ref.state.scale)}
        initialScale={initialScale}
        minScale={minScale}
        wheel={{ step: isDesktop ? 0.1 : 0.04 }}
        onInit={handleInit}
        smooth={false}
        customTransform={(x: number, y: number, scale: number) =>
          `matrix(${scale}, 0,0, ${scale}, ${x}, ${y})`
        }
        panning={{ velocityDisabled: true }}
        ref={transformComponentRef}
      >
        <TransformComponent
          wrapperClass={cx(css.map__container, {
            [css.map__container_hidden]: !init
          })}
          contentStyle={{
            position: 'relative',
            left: isMobileOnly && !zoomTo ? '10%' : '',
            pointerEvents: isScaled ? 'auto' : 'none'
          }}
        >
          <div
            ref={ref}
            data-vaul-no-drag
            style={{
              width: `${BOARD_SIZE.width}px`,
              height: `${BOARD_SIZE.height}px`
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {tables?.map((table) => (
              <MapItem
                ref={(ref) => {
                  if (
                    tables.length > tablesElements.length &&
                    table.map_item.type === 'place' &&
                    ref !== null &&
                    !tablesElements.some(
                      (el) => el.dataset.id === String(table.id)
                    )
                  ) {
                    setTablesElements((prev) => [...prev, ref])
                  }
                }}
                key={table.id}
                mapIdx={mapIdx}
                selectedTableId={selectedTableId}
                table={table}
                tablesElements={tablesElements}
                tableTime={timeSliceTables[table.map_item.id]}
                clickableDisabled={clickableDisabled}
                isNoInfo={isNoInfo || (isDesktop ? scale < 1 : scale < 0.8)}
                onlyView={onlyView}
                zoomTo={zoomTo}
                isPickTable={isPickTable}
                deposits={deposits}
                withDepositsInfo={withDepositsInfo}
                withComingSoon={withComingSoon}
                withRecentlyCompleted={withRecentlyCompleted}
                renderTransferTablePopup={renderTransferTablePopup}
              />
            ))}
          </div>
        </TransformComponent>
      </TransformWrapper>
    </section>
  )
}
