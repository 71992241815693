/* eslint-disable boundaries/element-types */
import { type Dispatch, type SetStateAction, useState } from 'react'

import { Comment } from '@/features/comment'
import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './CommentActionsPopover.module.css'

type CommentActionsPopoverProps = {
  comment: UserComment
  deleteCommentHandler: (commentId: number) => Promise<void>
  setCommentToEdit: Dispatch<SetStateAction<UserComment | undefined>>
}

export default function CommentActionsPopover({
  comment,
  deleteCommentHandler,
  setCommentToEdit
}: CommentActionsPopoverProps) {
  const [open, setOpen] = useState(false)

  const setCommentToEditHandler = () => {
    setCommentToEdit(comment)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button size="xxs" variant="transparent" className={css.more}>
          <Icon name="dotsHorizontal" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        variant="glass"
        side="left"
        className={css.popover}
      >
        <Comment.DeleteComment
          commentId={comment.id}
          className={css.button}
          contentClassName={css.button__content}
          onDelete={() => setOpen(false)}
          deleteCommentHandler={deleteCommentHandler}
        />
        <Button
          size="sm"
          variant="transparent"
          className={css.button}
          contentClassName={css.button__content}
          onClick={setCommentToEditHandler}
        >
          Редактировать
          <Icon name="pencil" />
        </Button>
      </PopoverContent>
    </Popover>
  )
}
