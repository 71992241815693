/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { type ItemProps, type ListProps, Virtuoso } from 'react-virtuoso'

import { INFINITE_SCROLL_RES } from '@/app/config'
import {
  MobileReservationSteps,
  ReservationSkipStep,
  selectReservationIsNewGuest,
  selectReservationSkipStep,
  setMobileReservationStep,
  setReservationGuest,
  setReservationIsNewGuest,
  setReservationSkipStep
} from '@/entities/create-update-reservation'
import {
  MobileSimpleSearchGuestCard,
  MobileSimpleSearchGuestCardTelephony,
  useAnonymousGuestQuery,
  useGuestsQuery
} from '@/entities/guest'
import {
  selectSearchGuestPhone,
  setSearchGuestPhone
} from '@/entities/search-guests'
import {
  selectSelectedAddressId,
  selectSelectedChainId
} from '@/entities/session'
import { useTelephonyWsQuery } from '@/entities/telephony'
import { useDebounce } from '@/shared/hooks'
import { formatPhoneNumberRU } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Error, InfoBox, Loading } from '@/shared/ui'

import css from './MobileGuestsSearchList.module.css'

type Context = {
  callsData: ResponseTelephonyWS[]
  phoneNum: string
  selectGuestHandler: (guest: BaseGuest) => void
  isContentLoading: boolean
  anonymousGuest: Guest
}

function Header({ context }: { context?: Context }) {
  if (context?.callsData.length === 0) {
    return <div className={css.header} />
  }

  return (
    <div>
      <div className={css.header} />
      <div className={css.item}>
        <MobileSimpleSearchGuestCard
          data-vaul-no-drag
          guest={context!.anonymousGuest}
          searchValue={context?.phoneNum}
          isAnonymous
          selectGuestHandler={context!.selectGuestHandler}
        />
      </div>
      <p className={css.title}>Телефония</p>
      {context?.callsData?.map((calls) => (
        <div key={calls.phone_caller ?? calls.guest?.id} className={css.item}>
          <MobileSimpleSearchGuestCardTelephony
            key={calls.guest?.id ?? calls.phone_caller}
            data-vaul-no-drag
            guest={calls.guest}
            phoneNewGuest={calls.phone_caller}
            status={calls.status}
            searchValue={context?.phoneNum}
            selectGuestHandler={context?.selectGuestHandler}
          />
        </div>
      ))}
    </div>
  )
}

function WrapperCard(props: ItemProps<Guest>) {
  return <div {...props} className={css['wrapper-card']} />
}

export function EmptyPlaceholder({ context }: { context?: Context }) {
  if (context?.callsData.length === 0) {
    return (
      <InfoBox className={css.placeholder} noIcon>
        Ничего не найдено
      </InfoBox>
    )
  }

  return null
}

export function List({
  context,
  ...props
}: ListProps & {
  context?: Context
}) {
  return (
    <div>
      <p className={css.title}>База гостей</p>
      <div {...props} />
    </div>
  )
}

export default function MobileGuestsSearchList() {
  const dispatch = useAppDispatch()
  const chain = useAppSelector(selectSelectedChainId)
  const address = useAppSelector(selectSelectedAddressId)
  const isNewGuest = useAppSelector(selectReservationIsNewGuest)
  const skipStep = useAppSelector(selectReservationSkipStep)

  const phoneNum = useAppSelector(selectSearchGuestPhone)
  const debouncedSearchPhone = useDebounce(phoneNum)

  const [page, setPage] = useState(1)
  const [guests, setGuests] = useState<Guest[]>([])

  useEffect(() => {
    if (skipStep === ReservationSkipStep.GUEST) {
      dispatch(setReservationSkipStep(undefined))
      dispatch(setMobileReservationStep(MobileReservationSteps.TWO))
    }
  }, [skipStep])

  const { data, isError, isLoading, refetch, isFetching, isSuccess } =
    useGuestsQuery(
      chain && address
        ? {
            chain,
            address,
            limit: INFINITE_SCROLL_RES,
            offset: (page - 1) * INFINITE_SCROLL_RES,
            phone: debouncedSearchPhone,
            is_anonymous: false
          }
        : skipToken,
      { refetchOnMountOrArgChange: true }
    )

  const { data: anonymousGuest } = useAnonymousGuestQuery(
    chain && address
      ? {
          chainId: chain,
          addressId: address
        }
      : skipToken
  )

  const { data: callsData } = useTelephonyWsQuery(
    address
      ? {
          addressId: address
        }
      : skipToken
  )

  const filteredCallsData = callsData?.[callsData?.length - 1]?.filter((call) =>
    formatPhoneNumberRU(
      call.guest ? call.guest.phone : call.phone_caller
    )?.startsWith(formatPhoneNumberRU(debouncedSearchPhone))
  )

  const selectGuestHandler = (guest: BaseGuest) => {
    dispatch(setReservationGuest(guest))
    dispatch(setMobileReservationStep(MobileReservationSteps.TWO))
  }

  const loadMoreRows = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    setPage(1)
    setGuests([])
  }, [debouncedSearchPhone])

  useEffect(() => {
    if (
      isSuccess &&
      data &&
      !isFetching &&
      data.count <= guests.length &&
      isNewGuest
    ) {
      setGuests(data.results)
      dispatch(setReservationIsNewGuest(false))
    } else if (isSuccess && data && !isFetching) {
      setGuests((prev) => [...prev, ...data.results])
    }
  }, [isSuccess, data])

  useEffect(() => {
    return () => {
      dispatch(setSearchGuestPhone(''))
    }
  }, [])

  const isContentLoading =
    (isLoading && page > 1) ||
    (isFetching && page === 1) ||
    phoneNum !== debouncedSearchPhone

  if (isError) {
    return <Error refetch={refetch} />
  }

  if (
    debouncedSearchPhone.length === 11 &&
    !isContentLoading &&
    !data?.results.length
  ) {
    const guest = {
      id: -1,
      last_name: 'гость',
      first_name: 'Новый',
      tags: [],
      comment: null,
      phone: debouncedSearchPhone
    }

    return (
      <div className={css.empty}>
        <MobileSimpleSearchGuestCard
          data-vaul-no-drag
          guest={guest}
          searchValue={phoneNum}
          isNewGuest
          selectGuestHandler={selectGuestHandler}
        />
      </div>
    )
  }

  if (isContentLoading || !anonymousGuest) {
    return (
      <div className={css.placeholder}>
        <Loading />
      </div>
    )
  }

  return (
    <div className={css.container}>
      <Virtuoso
        className={css.guests}
        totalCount={guests.length}
        defaultItemHeight={82}
        data={guests}
        endReached={loadMoreRows}
        increaseViewportBy={200}
        context={{
          callsData: filteredCallsData ?? [],
          phoneNum,
          selectGuestHandler,
          isContentLoading,
          anonymousGuest
        }}
        components={{
          Header,
          Item: WrapperCard,
          EmptyPlaceholder,
          List
        }}
        itemContent={(_, guest) => (
          <MobileSimpleSearchGuestCard
            key={guest.id}
            data-vaul-no-drag
            guest={guest}
            searchValue={phoneNum}
            selectGuestHandler={selectGuestHandler}
          />
        )}
      />
    </div>
  )
}
