/* eslint-disable boundaries/element-types */
import { useState } from 'react'

import { cx } from 'class-variance-authority'

import { SearchGuestSteps, setSearchGuestStep } from '@/entities/search-guests'
import {
  findStartingIntersection,
  formatName,
  formatPhoneNumberRU
} from '@/shared/lib'
import { useAppDispatch } from '@/shared/model'
import {
  Button,
  Card,
  Icon,
  Tag,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'

import css from './MobileSimpleSearchGuestCard.module.css'

type MobileSimpleSearchGuestCardProps = {
  guest: BaseGuest
  searchValue?: string
  isAnonymous?: boolean
  isNewGuest?: boolean
  selectGuestHandler: (guest: BaseGuest) => void
}

export default function MobileSimpleSearchGuestCard({
  guest,
  searchValue = '',
  isAnonymous = false,
  isNewGuest = false,
  selectGuestHandler
}: MobileSimpleSearchGuestCardProps) {
  const [open, setOpen] = useState(false)
  const { first_name, last_name, phone, tags } = guest
  const guestName = formatName(first_name, last_name)
  const isPhone = !Number.isNaN(+searchValue)
  const dispatch = useAppDispatch()

  const [matchedPhone, otherPhonePart] = findStartingIntersection(
    formatPhoneNumberRU(phone),
    formatPhoneNumberRU(searchValue)
  )

  const onClick = () => {
    if (isAnonymous) {
      selectGuestHandler(guest)
    }
    if (isNewGuest) {
      dispatch(setSearchGuestStep(SearchGuestSteps.two))
    }
    selectGuestHandler(guest)
  }

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Card
          className={cx(css.card, { [css.card_xl]: isAnonymous || isNewGuest })}
          onClick={isAnonymous || isNewGuest ? onClick : undefined}
        >
          <div className={css.card__guest}>
            <span
              className={cx(css.card__name, {
                [css.card__name_anonymous]: isAnonymous
              })}
            >
              {guestName}
            </span>
            {phone && (
              <div className={css.card__text}>
                <span className={cx(isPhone && css.card__text_active)}>
                  {matchedPhone}
                </span>
                <span>{otherPhonePart}</span>
              </div>
            )}
          </div>
          {tags[0] && <Tag tag={tags[0]} size="xs" />}
          {isAnonymous && (
            <Icon
              className={cx(css.card__icon, {
                [css.card__icon_anonymous]: isAnonymous
              })}
              name="user"
              size={20}
            />
          )}
          {isNewGuest && (
            <Icon className={css.card__icon} name="plus" size={20} />
          )}
        </Card>
      </VaulTrigger>
      <VaulContent isVisibleButtonClose={false} onClose={() => setOpen(false)}>
        <div className={css.vaul__content}>
          <div className={css.vaul__info}>
            <p className={css.vaul__text}>Подтвердите номер телефона</p>
            <p className={css.vaul__phone}>{formatPhoneNumberRU(phone)}</p>
          </div>
          <Button variant="primary" size="lg" onClick={onClick}>
            Подтвердить
            <Icon name="arrowRight" />
          </Button>
          <Button size="lg" onClick={() => setOpen(false)}>
            Отмена
            <Icon name="x" />
          </Button>
        </div>
      </VaulContent>
    </Vaul>
  )
}
