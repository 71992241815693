import { memo } from 'react'

import cn from 'classnames'

import { selectSelectedTimelineTable, selectTables } from '@/entities/timeline'
import { useAppSelector } from '@/shared/model'
import { Icon } from '@/shared/ui'

import css from './TablesInfoList.module.css'

export const TablesInfoList = memo(function TablesInfoList() {
  const tables = useAppSelector(selectTables)
  const selectedTimelineTable = useAppSelector(selectSelectedTimelineTable)

  const numberClassName = (id: number) => {
    return cn(css.root__number, {
      [css.root__number_selected]:
        selectedTimelineTable && selectedTimelineTable === id
    })
  }

  return (
    <>
      {tables.map((table) => (
        <div className={css.root} key={table.id}>
          <span className={numberClassName(table.id)}>
            №{table.item_number}
          </span>
          <span className={cn(css.root__amount)}>
            {`${table.min_persons_count}-${table.max_persons_count}`}
            <Icon name="users" />
          </span>
        </div>
      ))}
    </>
  )
})
