import { PATHS } from '@/shared/lib'
import { type IconNames } from '@/shared/ui'

export type MenuItemType = {
  title: string
  icon: IconNames
  link: string
}

export const MENU_ITEMS: MenuItemType[] = [
  {
    title: 'Карта',
    icon: 'map',
    link: PATHS.map
  },
  {
    title: 'Таймлайн',
    icon: 'timeline',
    link: PATHS.timeline
  },
  {
    title: 'Брони',
    icon: 'book',
    link: PATHS.reservations
  },
  {
    title: 'Лист\n ожиданий',
    icon: 'clock',
    link: PATHS.waitingList
  },
  {
    title: 'Поиск гостей',
    icon: 'search',
    link: PATHS.guests
  }
]
