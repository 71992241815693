/* eslint-disable boundaries/element-types */
import { type Dispatch, type SetStateAction, useEffect } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { resetMap, selectTableToChangeTo } from '@/entities/map'
import { useReservationQuery } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { MapActions } from '@/features/map-actions'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Loading, VaulFooter } from '@/shared/ui'
import { MobileMapHalls } from '@/widgets/map'

import { MobileChangeReservationTableMap } from '../../MobileChangeReservationTableMap'

import css from './MobileChangeReservationTableVaulContent.module.css'

type MobileChangeReservationTableVaulContentProps = {
  setOpen: Dispatch<SetStateAction<boolean>>
  reservationId: number
  isWithoutRefetchOnMountOrArgChange?: boolean
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

export default function MobileChangeReservationTableVaulContent({
  setOpen,
  reservationId,
  isWithoutRefetchOnMountOrArgChange = false,
  updateReservationsList
}: MobileChangeReservationTableVaulContentProps) {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const tablesToChangeTo = useAppSelector(selectTableToChangeTo)

  const { data, isFetching } = useReservationQuery(
    addressId && reservationId && !Number.isNaN(+reservationId)
      ? { addressId, reservationId: +reservationId }
      : skipToken,
    { refetchOnMountOrArgChange: !isWithoutRefetchOnMountOrArgChange }
  )

  useEffect(() => {
    return () => {
      dispatch(resetMap())
    }
  }, [])

  if (isFetching || !data) {
    return <Loading />
  }

  const tableToChangeTo = tablesToChangeTo?.[0]

  return (
    <section className={css.map}>
      <MobileChangeReservationTableMap
        startDate={data.start_date}
        endDate={data.end_date}
        personsCount={data.persons_count}
        places={data.places}
      />
      <VaulFooter className={css.map__footer}>
        <MobileMapHalls />
        {tableToChangeTo ? (
          <MapActions.ChangeTable
            className={css.map__button}
            reservationId={reservationId}
            size="lg"
            variant="primary"
            updateReservationsList={updateReservationsList}
          >
            Пересадить за столик №{tableToChangeTo.item_number}
          </MapActions.ChangeTable>
        ) : (
          <Button size="lg" className={css.map__button} disabled>
            Выберите столик, чтобы пересадить гостя
          </Button>
        )}
      </VaulFooter>
    </section>
  )
}
