import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import { addDays, format, isEqual, isSameDay, subDays } from 'date-fns'
import { isMobileOnly } from 'react-device-detect'

import { config } from '@/app/config'
import { selectSelectedAddressId } from '@/entities/session'
import { useTimelineTablesWSQuery } from '@/entities/timeline/api/timelineApi'
import {
  selectCurDate,
  selectHours,
  selectMinutesLinePosition,
  selectTables
} from '@/entities/timeline/model/selectors'
import {
  setMinutesLinePosition,
  setTimelineTables,
  unselectTimelineTable
} from '@/entities/timeline/model/slice'
import { unselectTimelineTableThunk } from '@/entities/timeline/model/thunk'
import { Timeline } from '@/features/timeline'
import { useDraggableScroll } from '@/shared/hooks'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Error, Loading, Skeleton } from '@/shared/ui'

import { useMinLineShift } from '../lib'

import css from './TimelineWidget.module.css'
const { GROUP_HOUR_COEFFICIENT } = config

interface TimelineWidgetProps {
  isShort?: boolean
  showEmptySuggestion?: boolean
  selectedTables?: Table[]
  mapItem?: MapItem
}

export function TimelineWidget({
  isShort = false,
  showEmptySuggestion = false,
  selectedTables,
  mapItem
}: TimelineWidgetProps) {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const scrollShift = useMinLineShift()
  const containerRef = useRef<HTMLDivElement>(null)
  const draggableEvents = useDraggableScroll(containerRef)
  const now = nowWithTimezone()
  const [curTime, setCurTime] = useState(now)

  const hours = useAppSelector(selectHours)
  const tables = useAppSelector(selectTables)
  const curDate = useAppSelector(selectCurDate)
  const minutesLinePosition = useAppSelector(selectMinutesLinePosition)

  const { data, isFetching, refetch, isError } = useTimelineTablesWSQuery(
    addressId
      ? {
          addressId,
          date: format(curDate, 'yyyy-MM-dd')
        }
      : skipToken
  )

  const { data: yesterdayData } = useTimelineTablesWSQuery(
    addressId
      ? {
          addressId,
          date: format(subDays(curDate, 1), 'yyyy-MM-dd')
        }
      : skipToken
  )

  const { data: tomorrowData } = useTimelineTablesWSQuery(
    addressId
      ? {
          addressId,
          date: format(addDays(curDate, 1), 'yyyy-MM-dd')
        }
      : skipToken
  )

  const isToday = isSameDay(curDate, now)

  useEffect(() => {
    if (!isToday || !scrollShift) return
    containerRef.current?.scrollTo({
      left: minutesLinePosition - scrollShift,
      behavior: 'instant'
    })
  }, [data, yesterdayData, tomorrowData, isToday, scrollShift])

  useEffect(() => {
    if (data?.length && yesterdayData?.length && tomorrowData?.length) {
      const res = data[data.length - 1].tables.map((table, index) => ({
        ...table,
        reservations: [
          ...new Set([
            ...table.reservations,
            ...yesterdayData[yesterdayData.length - 1].tables[index]
              .reservations,
            ...tomorrowData[tomorrowData.length - 1].tables[index].reservations
          ])
        ]
      }))
      dispatch(setTimelineTables(res))
    }

    if (!isShort) dispatch(unselectTimelineTable())

    return () => {
      dispatch(unselectTimelineTableThunk())
    }
  }, [data, yesterdayData, tomorrowData])

  useEffect(() => {
    if (!isToday) return

    const timer = setInterval(() => {
      const now = nowWithTimezone()
      if (!isEqual(now, curTime)) {
        setCurTime(now)
        dispatch(setMinutesLinePosition())
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [curTime, isToday])

  useLayoutEffect(() => {
    if (!isToday) return
    dispatch(setMinutesLinePosition())
  }, [curDate])

  if (isError) {
    return <Error refetch={refetch} />
  }

  if (
    isFetching ||
    !scrollShift ||
    !data?.length ||
    !yesterdayData?.length ||
    !tomorrowData?.length
  ) {
    if (isShort && isMobileOnly) {
      return <Skeleton className={css.skeleton} />
    }

    return <Loading />
  }

  return (
    <section
      {...draggableEvents}
      ref={containerRef}
      className={cx(css.root, { [css.root_short]: isShort })}
      data-vaul-no-drag
    >
      <div
        className={css.root__container}
        style={{
          width: `calc(65px + ${
            (hours.length - 1) / GROUP_HOUR_COEFFICIENT + 1
          } * var(--timeline-cell-width) - ${isShort ? 100 : 0}px)`
        }}
      >
        <div
          className={cx(css.h, css.v, css.root__corner, {
            [css.root__corner_mobile]: isMobileOnly
          })}
        />
        <div
          className={cx(css.v, css.root__columns, {
            [css.root__columns_short]: isShort,
            [css.root__columns_mobile]: isMobileOnly
          })}
        >
          <Timeline.HoursInterval isShort={isShort} />
        </div>
        <div
          className={cx(css.h, css.root__rows, {
            [css.root__rows_short]: isShort
          })}
          style={{
            zIndex: isMobileOnly ? 0 : 1,
            gridTemplateRows: `repeat(${
              selectedTables ? selectedTables.length : tables.length
            }, ${isMobileOnly ? '44px' : 'var(--timeline-cell-height)'})`
          }}
        >
          {isMobileOnly ? (
            <div style={{ paddingLeft: 54 }} />
          ) : (
            <Timeline.Tables />
          )}
        </div>
        <div
          className={cx(css.root__cells, {
            [css.root__cells_short]: isShort,
            [css.root__cells_mobile]: isMobileOnly
          })}
        >
          {!isShort && isToday && (
            <div
              className={css.hour_line}
              style={{ left: minutesLinePosition }}
            />
          )}
          <Timeline.Reservations
            showEmptySuggestion={showEmptySuggestion}
            selectedTables={selectedTables}
            mapItem={mapItem}
          />
        </div>
      </div>
    </section>
  )
}
