/* eslint-disable boundaries/element-types */
import { type MouseEvent, type PropsWithChildren, useState } from 'react'

import { useUpdateReservationMutation } from '@/entities/reservation'
import {
  ReservationSortEnum,
  ReservationStatusEnum,
  selectReservationsSortType
} from '@/entities/reservations'
import {
  selectCanEditAddress,
  selectSelectedAddressId
} from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps
} from '@/shared/ui'
// import { ConfirmInProgress } from '@/widgets/reservation/ConfirmInProgress'

type InProgressReservationProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number | string
    className?: string
    reservationsQueryParams?: ReservationsParams
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation | Visit>
    ) => void
  }

export function InProgressReservation({
  children,
  reservationId,
  className,
  reservationsQueryParams,
  updateReservationsList,
  ...props
}: InProgressReservationProps) {
  const [open, setOpen] = useState(false)
  const addressId = useAppSelector(selectSelectedAddressId)
  const canEditAddress = useAppSelector(selectCanEditAddress)
  const sortType = useAppSelector(selectReservationsSortType)

  const [setInProgress, { isLoading }] = useUpdateReservationMutation()

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  const setInProgressHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (!addressId || !reservationId || Number.isNaN(+reservationId)) return

    try {
      const data = await setInProgress({
        addressId,
        reservationId: +reservationId,
        reservationsQueryParams,
        body: { status: ReservationStatusEnum.inProgress }
      }).unwrap()
      updateReservationsList?.(
        data.id,
        ReservationSortEnum.default !== sortType,
        { status: data.status }
      )
      setOpen(false)
    } catch {
      // do nothing
    }
  }

  return (
    // <ConfirmInProgress
    //   reservationId={+reservationId}
    //   reservationsQueryParams={reservationsQueryParams}
    //   updateReservationsList={updateReservationsList}
    // >
    //   <Button
    //     disabled={!canEditAddress}
    //     className={className}
    //     onClick={(e: MouseEvent) => e.stopPropagation()}
    //     {...props}
    //   >
    //     {children}
    //   </Button>
    // </ConfirmInProgress>
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button
          onClick={toggleDialogHandler}
          disabled={!canEditAddress}
          className={className}
          {...props}
        >
          {children}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent onClose={toggleDialogHandler}>
        <AlertDialogHeader>
          <AlertDialogTitle>Гости пришли ?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={setInProgressHandler}
            >
              Да
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel disabled={isLoading} onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
