/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable import/no-duplicates */
import { useState } from 'react'

import { cx } from 'class-variance-authority'
import { isEqual, startOfDay } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  type DateRange,
  DayPicker,
  type DayPickerDefaultProps,
  type StyledElement
} from 'react-day-picker'

import {
  formatDatePickerLabel,
  formatDatePickerLabelRange
} from './formatDatePickerLabel'
import { Templates } from './Templates/Templates'

import { Button } from '../Button'
import { Icon } from '../Icon'
import { Popover, PopoverContent, PopoverTrigger } from '../Popover'

import css from './DatePickerPopover.module.css'

type DatePickerPopoverProps = DayPickerDefaultProps & {
  disablePrevDays?: boolean
  disableNextDays?: boolean
  selected?: Date
  onSelect?: (date?: Date) => void
  rangeSelected?: DateRange
  onRangeSelect?: (range?: DateRange) => void
  isQuickButtons?: boolean
  withoutButtons?: boolean
}

export default function DatePickerPopover({
  disablePrevDays = false,
  disableNextDays = false,
  selected,
  onSelect,
  rangeSelected,
  onRangeSelect,
  isQuickButtons,
  withoutButtons = false
}: DatePickerPopoverProps) {
  const [open, setOpen] = useState(false)
  const isFillGap =
    (rangeSelected?.from && !rangeSelected?.to) ||
    (rangeSelected?.to &&
      rangeSelected?.from &&
      isEqual(startOfDay(rangeSelected.from), startOfDay(rangeSelected.to)))

  const className: Partial<StyledElement<string>> | undefined = {
    root: css.calendar__root,
    caption: css.calendar__caption,
    caption_label: css.calendar__caption_label,
    head_cell: css.calendar__head_cell,
    cell: css.calendar__cell,
    day: css.calendar__day,
    day_outside: css.calendar__day_outside,
    day_selected: css.calendar__day_selected,
    day_disabled: css.calendar__day_disabled,
    nav_button: css.calendar__nav_button,
    nav: css.calendar__nav,
    day_range_middle: !isFillGap ? css.calendar__day_range_middle : undefined,
    day_range_start: !isFillGap ? css.calendar__day_range_start : undefined,
    day_range_end: !isFillGap ? css.calendar__day_range_end : undefined
  }

  const selectHandler = (date?: Date) => {
    onSelect?.(date)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger asChild>
        <Button variant={open ? 'secondary' : 'tertiary'}>
          {onRangeSelect
            ? formatDatePickerLabelRange(rangeSelected)
            : formatDatePickerLabel(selected)}
          <Icon name="calendar" size={20} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        variant="glass"
        className={cx({ [css.content_quick]: isQuickButtons })}
      >
        {onRangeSelect ? (
          <DayPicker
            showOutsideDays
            mode="range"
            defaultMonth={selected}
            components={{
              IconLeft: () => <Icon name="arrowLeft" />,
              IconRight: () => <Icon name="arrowRight" />
            }}
            classNames={className}
            disabled={
              (disablePrevDays && { before: new Date() }) ||
              (disableNextDays && { after: new Date() })
            }
            locale={ru}
            selected={rangeSelected}
            onSelect={onRangeSelect}
          />
        ) : (
          <DayPicker
            showOutsideDays
            mode="single"
            defaultMonth={selected}
            components={{
              IconLeft: () => <Icon name="arrowLeft" />,
              IconRight: () => <Icon name="arrowRight" />
            }}
            classNames={className}
            disabled={
              (disablePrevDays && { before: new Date() }) ||
              (disableNextDays && { after: new Date() })
            }
            locale={ru}
            selected={selected}
            onSelect={selectHandler}
          />
        )}
        {!withoutButtons &&
          (isQuickButtons ? (
            <Templates
              dateInterval={rangeSelected}
              setInterval={onRangeSelect}
            />
          ) : (
            <Button
              className={css.clear}
              variant={selected ? 'secondary' : 'white'}
              onClick={() => selectHandler(undefined)}
            >
              Все время
            </Button>
          ))}
      </PopoverContent>
    </Popover>
  )
}
