import { type Dispatch, type SetStateAction } from 'react'

import { useParams } from 'react-router-dom'

import {
  MobileReservationGuest,
  MobileReservationSource,
  MobileReservationTableCard,
  MobileReservationTagsAndComments,
  MobileReservationTimeCard
} from '@/entities/reservation'
import { ReservationStatusEnum } from '@/entities/reservations'
import { Reservation } from '@/features/reservation'
import { Icon, VaulBody, VaulFooter } from '@/shared/ui'

import { ReservationActions } from './ReservationActions'

import css from './ReservationVaulCardContent.module.css'

type ReservationVaulCardContentProps = {
  reservation: Reservation
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
  setPrevReservationId?: Dispatch<SetStateAction<number | null>>
}

export default function ReservationVaulCardContent({
  reservation,
  updateReservationsList,
  setPrevReservationId
}: ReservationVaulCardContentProps) {
  const { id: reservationId } = useParams()

  const {
    id,
    guest,
    persons_count,
    status,
    start_date,
    end_date,
    main_table_number,
    main_table_id,
    hall_id,
    hall_name,
    places,
    source,
    link,
    tags
  } = reservation

  return (
    <VaulBody className={css.card}>
      <MobileReservationGuest guest={guest} />
      <MobileReservationTimeCard
        status={status}
        startDate={start_date}
        endDate={end_date}
        reservationId={id}
        isProgressBar
      />
      {places.map((table, idx) => (
        <MobileReservationTableCard
          key={table}
          idx={idx}
          tableNumber={table === main_table_id ? main_table_number : undefined}
          tableId={table}
          hallId={hall_id}
          hallName={hall_name}
          startDate={start_date}
          endDate={end_date}
          personsCount={persons_count}
          reservationId={id}
          status={status}
          setPrevReservationId={setPrevReservationId}
          updateReservationsList={updateReservationsList}
        />
      ))}
      <MobileReservationTagsAndComments
        reservationId={id}
        tags={tags}
        updateReservationsList={updateReservationsList}
      />
      <MobileReservationSource source={source} linkName={link?.name ?? null} />
      {/* <Reservation.Delete
        size="lg"
        variant="transparent"
        className={css.delete}
        reservationId={id}
        updateReservationsList={updateReservationsList}
      >
        <Icon name="trash" />
        Удалить бронь
      </Reservation.Delete> */}
      {status !== ReservationStatusEnum.completed &&
        status !== ReservationStatusEnum.canceled &&
        (status === ReservationStatusEnum.inProgress ? (
          <Reservation.Complete
            size="lg"
            variant="transparent"
            className={css.delete}
            reservationId={id}
            updateReservationsList={updateReservationsList}
          >
            Завершить визит
            <Icon name="x" />
          </Reservation.Complete>
        ) : (
          <Reservation.Cancel
            size="lg"
            variant="transparent"
            className={css.delete}
            reservationId={id}
            updateReservationsList={updateReservationsList}
          >
            <Icon name="x" />
            Отменить бронь
          </Reservation.Cancel>
        ))}
      <VaulFooter isTgBot={Boolean(reservationId)}>
        <ReservationActions
          status={status}
          reservationId={id}
          updateReservationsList={updateReservationsList}
        />
      </VaulFooter>
    </VaulBody>
  )
}
