/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable boundaries/element-types */
import { type ComponentPropsWithoutRef } from 'react'

import 'swiper/css'

import { cx } from 'class-variance-authority'
import { format } from 'date-fns'
import { A11y, FreeMode, Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Badge, Icon } from '@/shared/ui'

import { getSourceText } from '../../lib'

import css from './MobileReservationBadgeCarousel.module.css'

type MobileReservationBadgeCarouselProps = ComponentPropsWithoutRef<'div'> & {
  personsCount: number
  tableNumber?: string
  source?: ReservationSource
  linkName: string | null
  deposit?: null
  startDate: Date
  tags?: SimpleTag[]
  isWhite?: boolean
}

export default function MobileReservationBadgeCarousel({
  personsCount,
  tableNumber,
  source,
  linkName,
  deposit,
  startDate,
  className,
  tags,
  isWhite = false
}: MobileReservationBadgeCarouselProps) {
  return (
    <div
      className={cx(css.card, className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div style={{ overflow: 'hidden' }}>
        <Swiper
          modules={[A11y, FreeMode, Mousewheel]}
          slidesPerView="auto"
          freeMode
          mousewheel={{
            sensitivity: 0.2
          }}
          className={css.card__scroll}
          spaceBetween={8}
        >
          {tableNumber && (
            <SwiperSlide style={{ width: 'auto' }}>
              <Badge className={css.card__badge} variant="orange">
                <Icon name="table" />
                {`№${tableNumber}`}
              </Badge>
            </SwiperSlide>
          )}
          <SwiperSlide style={{ width: 'auto' }}>
            <Badge className={css.card__badge} variant="gray">
              <Icon name="users" />
              {personsCount}
            </Badge>
          </SwiperSlide>
          {deposit && (
            <SwiperSlide style={{ width: 'auto' }}>
              <Badge variant="tintedBlue" className={css.card__badge}>
                <Icon name="banknote" />
                {deposit}
              </Badge>
            </SwiperSlide>
          )}
          {source && (
            <SwiperSlide style={{ width: 'auto' }}>
              <Badge
                className={css.card__badge}
                variant={isWhite ? 'gray' : 'default'}
              >
                <Icon name="announcement" />
                {getSourceText(source, linkName)}
              </Badge>
            </SwiperSlide>
          )}
          <SwiperSlide style={{ width: 'auto' }}>
            <Badge
              className={css.card__badge}
              variant={isWhite ? 'gray' : 'default'}
            >
              <Icon name="calendar" />
              {format(startDate, 'dd.LL.yyyy')}
            </Badge>
          </SwiperSlide>
          {tags?.map((tag) => (
            <SwiperSlide style={{ width: 'auto' }}>
              <Badge
                key={tag.id}
                className={css.card__badge}
                variant={isWhite ? 'gray' : 'default'}
              >
                {tag.name}
              </Badge>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
