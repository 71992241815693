import * as Checkbox from '@radix-ui/react-checkbox'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Label } from '@/shared/ui'

import css from './FilterMulti.module.css'

type FilterMultiProps = {
  label: string
  options: Option[]
  queryKey: string
}

export default function FilterMulti({
  label,
  options,
  queryKey
}: FilterMultiProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const checkedChangeHandler = (
    value: string,
    checked: Checkbox.CheckedState
  ) => {
    if (typeof checked === 'boolean') {
      const paramsValues = searchParams.getAll(queryKey)
      const valueExists = Boolean(
        paramsValues.find((paramsValue) => paramsValue === value)
      )

      if (valueExists) {
        const newValues = paramsValues.filter(
          (paramValue) => paramValue !== value
        )
        searchParams.delete(queryKey)
        newValues.forEach((newValue) => searchParams.append(queryKey, newValue))
      } else searchParams.append(queryKey, value)

      navigate(pathname + '?' + searchParams.toString())
    }
  }

  return (
    <div className={css.filter}>
      <Label className={css.filter__label}>{label}</Label>
      <div className={css.filter__options}>
        {options.map((option) => {
          const isChecked = Boolean(
            searchParams
              .getAll(queryKey)
              .find((paramsValue) => paramsValue === option.value)
          )
          return (
            <Checkbox.Root
              key={option.value}
              checked={isChecked}
              onCheckedChange={(checked) =>
                checkedChangeHandler(option.value, checked)
              }
              className={css.filter__item}
            >
              {option.label}
            </Checkbox.Root>
          )
        })}
      </div>
    </div>
  )
}
