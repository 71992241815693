/* eslint-disable boundaries/element-types */
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { config } from '@/app/config'
import { selectSelectedAddressId } from '@/entities/session'
import {
  deleteWaitingListRecord,
  updateWaitingListRecord
} from '@/entities/waiting-list'
import { getTz } from '@/shared/api'
import { formatName, formatPhoneNumberRU, getCountdownSec } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Button, Card } from '@/shared/ui'

import css from './NoGuestDialogContent.module.css'

type NoGuestDialogContentProps = {
  record: WaitingListRecord
  toggleDialog: () => void
}

export default function NoGuestDialogContent({
  record,
  toggleDialog
}: NoGuestDialogContentProps) {
  const { guest, id, called_at } = record
  const address = useAppSelector(selectSelectedAddressId)
  const tz = getTz()

  const guestCalledHandler = () => {
    if (!address || !tz) return

    updateWaitingListRecord(
      address,
      {
        id,
        called_at: formatInTimeZone(new Date(), tz, 'yyyy-MM-dd HH:mm:ss')
      },
      () => toggleDialog()
    )
  }

  const deleteFromWaitingListHandler = (id: number) => {
    if (!address) return
    deleteWaitingListRecord(address, { id }, () => toggleDialog())
  }

  const secondsLeft = called_at
    ? getCountdownSec(parseISO(called_at), config.TIMER_COUNTDOWN_SEC)
    : 0

  const isExpired = secondsLeft < 0

  return (
    <>
      <h1 className={css.title}>Гостя еще нет</h1>
      <Card className={css.card}>
        <div className={css.guest}>
          <p className={css.name}>
            {formatName(guest.first_name, guest.last_name)}
          </p>
          <p className={css.phone}>{formatPhoneNumberRU(guest.phone)}</p>
        </div>
        <p className={css.text}>
          Вы можете позвонить для уточнения брони или удалить ее
        </p>
      </Card>
      <div className={css.actions}>
        <Button variant="tertiary" size="lg" onClick={toggleDialog}>
          Отмена
        </Button>
        <Button
          variant="gray"
          size="lg"
          onClick={() => deleteFromWaitingListHandler(id)}
        >
          Удалить
        </Button>
        {(called_at === null || isExpired) && (
          <Button variant="primary" size="lg" onClick={guestCalledHandler}>
            {called_at === null ? 'Гостя позвали' : 'Продлить'}
          </Button>
        )}
      </div>
    </>
  )
}
