/* eslint-disable boundaries/element-types */
import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction
} from 'react'

import { Vaul, VaulContent, VaulTrigger } from '@/shared/ui'
import { ReservationVaulContent } from '@/widgets/reservation'

type ReservationVaulProps = PropsWithChildren & {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  slot: TimeSlot
}

export default function ReservationVaul({
  open,
  setOpen,
  slot,
  children
}: ReservationVaulProps) {
  return (
    <Vaul open={open} onOpenChange={(open) => slot.booked && setOpen(open)}>
      <VaulTrigger asChild>{children}</VaulTrigger>
      <VaulContent fullScreen>
        <ReservationVaulContent reservationId={slot.id} />
      </VaulContent>
    </Vaul>
  )
}
