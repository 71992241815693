/* eslint-disable boundaries/element-types */
/* eslint-disable import/no-duplicates */
import { useEffect, useState } from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import {
  MobileReservationSteps,
  selectReservationDate,
  selectReservationEnd,
  selectReservationPersonsCount,
  selectReservationStart,
  selectReservationTables,
  selectSelectedMaxPersonsCount,
  setMobileReservationStep,
  setReservationTables,
  useNewReservationGuestAvailable
} from '@/entities/create-update-reservation'
import { MapModeEnum, setMapMode } from '@/entities/map'
import { Reservation } from '@/features/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Button,
  Icon,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'
import { MobileMapHalls } from '@/widgets/map'

import css from './index.module.css'

export default function MobileReservationPickTableStep() {
  const [open, setOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const selectedTables = useAppSelector(selectReservationTables)
  const selectedPersonsCount = useAppSelector(selectReservationPersonsCount)
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const selectedMaxPersonsCount = useAppSelector(selectSelectedMaxPersonsCount)
  const [isAvailable, reservation] = useNewReservationGuestAvailable()

  const isNextStepAvailable = selectedTables.length > 0

  const renderContentSubmit = () => {
    const numberTablesText = selectedTables
      .map((table) => table.item_number)
      .join(', ')

    if (selectedTables.length === 1) {
      return (
        <>
          {`Выбрать столик (№${numberTablesText})`}
          <Icon name="restaurant" />
        </>
      )
    }

    if (selectedTables.length > 1) {
      return (
        <>
          <span
            className={css.button__submit__content}
          >{`Объединить столики (№${numberTablesText})`}</span>
          <Icon name="restaurant" />
        </>
      )
    }

    return (
      <>
        <Icon name="arrowUp" strokeWidth={3} />
        Выберите столик
      </>
    )
  }

  const backHandler = () => {
    dispatch(setMobileReservationStep(MobileReservationSteps.TWO))
    dispatch(setReservationTables([]))
  }

  const nextStepHandler = () => {
    dispatch(setMobileReservationStep(MobileReservationSteps.FOUR))
  }

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  useEffect(() => {
    dispatch(setMapMode(MapModeEnum.many))

    return () => {
      dispatch(setMapMode(MapModeEnum.one))
    }
  }, [])

  if (!selectedPersonsCount) {
    return null
  }

  return (
    <section className={css.map}>
      <VaulHeader className={css.header}>
        <Button size="icon" className={css.button__back} onClick={backHandler}>
          <Icon name="arrowLeft" />
        </Button>
        {selectedStart && (selectedEnd === null || selectedEnd) && (
          <Badge variant="primary" size="md" className={css.badge__datetime}>
            {`${format(selectedStart, 'HH:mm')}-${
              selectedEnd ? format(selectedEnd, 'HH:mm') : 'По ситуации'
            }`}
            <Badge className={css.badge__persons} size="xs">
              {selectedPersonsCount} <Icon name="users" size={12} />
            </Badge>
          </Badge>
        )}
        {selectedDate && (
          <Badge variant="primary" size="md" className={css.badge__datetime}>
            {format(selectedDate, 'd MMMM, EEEEEE', { locale: ru })}
          </Badge>
        )}
      </VaulHeader>
      <Reservation.TablePicker />
      <VaulFooter className={css.map__footer}>
        <MobileMapHalls />
        <AlertDialog open={open}>
          <AlertDialogTrigger asChild>
            <Button
              size="lg"
              className={css.button__submit}
              variant={isNextStepAvailable ? 'primary' : 'default'}
              disabled={!isNextStepAvailable || !isAvailable}
              onClick={
                reservation.personsCount <= selectedMaxPersonsCount
                  ? nextStepHandler
                  : toggleDialogHandler
              }
            >
              {renderContentSubmit()}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Количество гостей превышает количество мест
              </AlertDialogTitle>
              <AlertDialogDescription>
                Вы&nbsp;можете продолжить бронирование или вернуться назад
                и&nbsp;добавить дополнительный стол
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction asChild>
                <Button onClick={nextStepHandler}>Продолжить</Button>
              </AlertDialogAction>
              <AlertDialogCancel onClick={toggleDialogHandler}>
                Отмена
              </AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </VaulFooter>
    </section>
  )
}
