import { useEffect, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useCreateGuestMutation } from '@/entities/guest'
import { useCreateGuestWithoutInvalidationMutation } from '@/entities/guest/api/guestApi'
import { MobileGuestTagsAndComments } from '@/entities/reservation'
import { selectSearchGuestPhone } from '@/entities/search-guests'
import { selectSelectedChainId } from '@/entities/session'
import { useGuestTagsQuery } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Icon,
  Input,
  PhoneInput,
  VaulFooter
} from '@/shared/ui'

import {
  type CreateGuestFormSchema,
  createGuestFormSchema
} from '../model/createGuestFormSchema'

import css from './CreateGuestForm.module.css'

type CreateGuestFormProps = {
  successHandler: (guest?: Guest) => void
  phone?: string | null
  withoutInvalidation?: boolean
}

export default function CreateGuestForm({
  successHandler,
  phone,
  withoutInvalidation = false
}: CreateGuestFormProps) {
  const [tags, setTags] = useState<SimpleTag[]>([])
  const [comments, setComments] = useState<UserComment[]>([])
  const chainId = useAppSelector(selectSelectedChainId)
  const phoneNum = useAppSelector(selectSearchGuestPhone)

  const form = useForm<CreateGuestFormSchema>({
    resolver: zodResolver(createGuestFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: phone ?? phoneNum ?? ''
    }
  })

  const { data: tagsKit } = useGuestTagsQuery(chainId ? { chainId } : skipToken)
  const [
    createGuest,
    {
      isLoading: isCreateGuestLoading,
      isSuccess: isCreateGuestSuccess,
      data: guestData
    }
  ] = useCreateGuestMutation()
  const [
    createGuestWithoutInvalidation,
    {
      isLoading: isCreateGuestWithoutInvalidationLoading,
      isSuccess: isCreateGuestWithoutInvalidationSuccess,
      data: guestWithoutInvalidationData
    }
  ] = useCreateGuestWithoutInvalidationMutation()
  const isLoading =
    isCreateGuestLoading || isCreateGuestWithoutInvalidationLoading
  const isSuccess =
    isCreateGuestSuccess || isCreateGuestWithoutInvalidationSuccess

  useEffect(() => {
    if (isSuccess) {
      successHandler(
        withoutInvalidation ? guestWithoutInvalidationData : guestData
      )
    }
  }, [isSuccess])

  const submitHandler = ({
    firstName,
    lastName,
    phone
  }: CreateGuestFormSchema) => {
    if (!chainId || !tagsKit) return

    if (withoutInvalidation) {
      createGuestWithoutInvalidation({
        chainId,
        body: {
          first_name: firstName,
          last_name: lastName,
          phone: `+${phone}`,
          tags: tags.map(
            (t) => String(tagsKit.find(({ tag }) => tag === t.name)?.id) ?? ''
          ),
          comments: comments.map((comment) => comment.text)
        }
      })
    } else {
      createGuest({
        chainId,
        body: {
          first_name: firstName,
          last_name: lastName,
          phone: `+${phone}`,
          tags: tags.map(
            (t) => String(tagsKit.find(({ tag }) => tag === t.name)?.id) ?? ''
          ),
          comments: comments.map((comment) => comment.text)
        }
      })
    }
    toast.success('Гость успешно добавлен в книгу')
  }

  return (
    <div className={css.form}>
      <Form {...form}>
        <form
          className={css.form__content}
          onSubmit={form.handleSubmit(submitHandler)}
          noValidate
        >
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Введите имя"
                    label="Имя"
                    className={css.input}
                    classNameLabel={css.input__label}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Введите фамилию"
                    label="Фамилия"
                    className={css.input}
                    classNameLabel={css.input__label}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PhoneInput
                    value={field.value}
                    onValueChange={field.onChange}
                    placeholder="Введите телефон"
                    label="Телефон"
                    className={css.input}
                    classNameLabel={css.input__label}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <MobileGuestTagsAndComments
            tags={tags}
            setTags={setTags}
            comments={comments}
            setComments={setComments}
          />
          <VaulFooter>
            <Button
              type="submit"
              className={css.form__button}
              size="lg"
              variant={form.formState.isValid ? 'primary' : 'default'}
              disabled={isLoading || !form.formState.isValid}
              isLoading={isLoading}
            >
              {form.formState.isValid ? (
                'Создать карточку гостя'
              ) : (
                <>
                  <Icon name="arrowUp" strokeWidth={3} />
                  Заполните поля
                </>
              )}
            </Button>
          </VaulFooter>
        </form>
      </Form>
    </div>
  )
}
