import { baseApi, tagTypes } from '@/shared/api'
import { formatFiltersForRequest } from '@/shared/lib'

import { transformAllReservations, transformReservationById } from '../lib'

const {
  RESERVATIONS_TAG,
  RESERVATION_TAG,
  INPROGRESS_RESERVATIONS_TAG,
  MAP_TAG
} = tagTypes

export const reservationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    reservations: builder.query<ResponseReservations, ReservationsParams>({
      query: ({
        address,
        limit,
        offset,
        search,
        start_date,
        end_date,
        ordering,
        ordering_places,
        status,
        filters
      }) => {
        const params = new URLSearchParams()

        params.set('limit', limit.toString())
        params.set('offset', offset.toString())
        if (ordering) params.set('ordering', ordering)
        if (ordering_places) params.set('ordering_places', ordering_places)
        if (search) params.set('search', search)
        if (start_date) params.set('start_date', start_date)
        if (end_date) params.set('end_date', end_date)
        if (status) {
          for (let i = 0; i < status.length; i++) {
            params.append('status', status[i])
          }
        }

        return {
          url: `/book/addresses/${address}/reservations/?${params.toString()}${
            typeof filters === 'string'
              ? `&${filters}`
              : formatFiltersForRequest(filters)
          }`
        }
      },
      transformResponse: (response: ResponseReservationsPage) =>
        transformAllReservations(response),
      providesTags: [RESERVATIONS_TAG]
    }),
    inProgressReservations: builder.query<
      ResponseReservations,
      ReservationsParams
    >({
      query: ({
        address,
        limit,
        offset,
        search,
        ordering,
        ordering_places,
        filters
      }) => {
        const params = new URLSearchParams()

        params.set('limit', limit.toString())
        params.set('offset', offset.toString())
        params.set('status', 'in_progress')
        if (ordering) params.set('ordering', ordering)
        if (ordering_places) params.set('ordering_places', ordering_places)
        if (search) params.set('search', search)

        return {
          url: `/book/addresses/${address}/reservations/?${params.toString()}${
            typeof filters === 'string'
              ? `&${filters}`
              : formatFiltersForRequest(filters)
          }`
        }
      },
      transformResponse: (response: ResponseReservationsPage) =>
        transformAllReservations(response),
      providesTags: [RESERVATIONS_TAG, INPROGRESS_RESERVATIONS_TAG]
    }),
    reservation: builder.query<
      Reservation,
      { addressId: number; reservationId: number }
    >({
      query: ({ addressId, reservationId }) => ({
        url: `/book/addresses/${addressId}/reservations/${reservationId}/`
      }),
      providesTags: [RESERVATION_TAG],
      transformResponse: (response: ResponseReservationById): Reservation =>
        transformReservationById(response)
    }),
    createReservation: builder.mutation<
      ResponseNewReservation,
      { addressId: number; body: RequestNewReservation }
    >({
      query: ({ addressId, body }) => ({
        url: `/book/addresses/${addressId}/reservations/`,
        method: 'POST',
        body
      }),
      invalidatesTags: [RESERVATIONS_TAG, MAP_TAG]
    }),
    updateReservation: builder.mutation<Reservation, UpdateReservationParams>({
      query: ({ addressId, reservationId, body }) => ({
        url: `/book/addresses/${addressId}/reservations/${reservationId}/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [RESERVATION_TAG, MAP_TAG],
      transformResponse: (response: ResponseReservationById): Reservation =>
        transformReservationById(response)
    }),
    updateReservationWithoutInvalidation: builder.mutation<
      Reservation,
      UpdateReservationParams
    >({
      query: ({ addressId, reservationId, body }) => ({
        url: `/book/addresses/${addressId}/reservations/${reservationId}/`,
        method: 'PATCH',
        body
      })
    }),
    setReservationTags: builder.mutation<Reservation, SetReservationTagsParams>(
      {
        query: ({ addressId, reservationId, body }) => ({
          url: `book/addresses/${addressId}/reservations/${reservationId}/add-tags/`,
          method: 'POST',
          body: {
            tags: body.tags.map((tag) => tag.id)
          }
        }),
        async onQueryStarted(
          { addressId, reservationId, body },
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            reservationApi.util.updateQueryData(
              'reservation',
              {
                addressId,
                reservationId
              },
              (draft) => {
                return {
                  ...draft,
                  tags: body.tags
                }
              }
            )
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        },
        invalidatesTags: [RESERVATION_TAG, RESERVATIONS_TAG]
      }
    ),
    deleteReservation: builder.mutation<
      void,
      { addressId: number; reservationId: number }
    >({
      query: ({ addressId, reservationId }) => ({
        url: `/book/addresses/${addressId}/reservations/${reservationId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: [RESERVATIONS_TAG]
    })
  })
})

export const {
  useReservationsQuery,
  useDeleteReservationMutation,
  useUpdateReservationMutation,
  useReservationQuery,
  useLazyReservationQuery,
  useUpdateReservationWithoutInvalidationMutation,
  useCreateReservationMutation,
  useInProgressReservationsQuery,
  useSetReservationTagsMutation
} = reservationApi
