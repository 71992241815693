import * as RadioGroup from '@radix-ui/react-radio-group'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Label } from '@/shared/ui'

import css from './FilterSingle.module.css'

type FilterSingleProps = {
  label: string
  options: Option[]
  queryKey: string
}

export default function FilterSingle({
  label,
  options,
  queryKey
}: FilterSingleProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const value = searchParams.get(queryKey) ?? ''

  const valueChangeHandler = (value: string) => {
    searchParams.set(queryKey, value)
    navigate(pathname + '?' + searchParams.toString())
  }

  const deleteValueHandler = () => {
    if (searchParams.get(queryKey)) {
      searchParams.delete(queryKey)
      navigate(pathname + '?' + searchParams.toString())
    }
  }

  return (
    <RadioGroup.Root
      value={value}
      onValueChange={valueChangeHandler}
      className={css.filter}
    >
      <Label className={css.filter__label}>{label}</Label>
      <div className={css.filter__options}>
        {options.map((option) => (
          <RadioGroup.Item
            className={css.filter__item}
            key={option.value}
            value={option.value}
            onClick={deleteValueHandler}
          >
            {option.label}
          </RadioGroup.Item>
        ))}
      </div>
    </RadioGroup.Root>
  )
}
