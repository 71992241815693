import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useState
} from 'react'

import { cx } from 'class-variance-authority'

import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './ReservationsSortPopover.module.css'

type ReservationsSortDropdownProps = {
  ordering: ReservationsOrdering
  setOrdering: Dispatch<SetStateAction<ReservationsOrdering>>
} & PropsWithChildren

export default function ReservationsSortPopover({
  ordering,
  setOrdering,
  children
}: ReservationsSortDropdownProps) {
  const [open, setOpen] = useState(false)

  const changeSortType = (type: (typeof ordering)[keyof typeof ordering]) => {
    switch (true) {
      case type.startsWith('default'):
        return type.slice(8)
      case type.startsWith('-'):
        return `default_${type.slice(1)}`
      default:
        return `-${type}`
    }
  }

  const changeDateSortDirectionHandler = () => {
    setOrdering((prev) => ({
      date: changeSortType(prev.date) as ReservationsOrderingDate,
      tables: 'default_1'
    }))
  }

  const changeTablesSortDirectionHandler = () => {
    setOrdering((prev) => ({
      tables: changeSortType(prev.tables) as ReservationsOrderingTables,
      date: 'default_start_date'
    }))
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        align="start"
        variant="glass"
        className={css.popover}
      >
        <Button
          className={cx(css.button, {
            [css.button_selected]: !ordering.date.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={changeDateSortDirectionHandler}
        >
          Время и дата
          {ordering.date && (
            <div className={css.button__subtitle}>
              {!ordering.date.startsWith('-') &&
                !ordering.date.startsWith('default') && (
                  <>
                    00:00-&gt;23:59
                    <Icon name="chevronDownDouble" />
                  </>
                )}
              {ordering.date.startsWith('-') && (
                <>
                  23:59-&gt;00:00
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
        <Button
          className={cx(css.button, {
            [css.button_selected]: !ordering.tables.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={changeTablesSortDirectionHandler}
        >
          Номер стола
          {ordering.tables && (
            <div className={css.button__subtitle}>
              {!ordering.tables.startsWith('-') &&
                !ordering.tables.startsWith('default') && (
                  <>
                    1-&gt;99
                    <Icon name="chevronDownDouble" />
                  </>
                )}
              {ordering.tables.startsWith('-') && (
                <>
                  99-&gt;1
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
      </PopoverContent>
    </Popover>
  )
}
