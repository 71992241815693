/* eslint-disable boundaries/element-types */
import {
  type ComponentPropsWithoutRef,
  type Dispatch,
  type MouseEvent,
  type SetStateAction
} from 'react'

import {
  ReservationSkipStep,
  setReservationGuest,
  setReservationSkipStep,
  setReservationTables
} from '@/entities/create-update-reservation'
import { useAppDispatch } from '@/shared/model'
import {
  Button,
  type ButtonStyleProps,
  VaulContent,
  VaulNested,
  VaulTrigger
} from '@/shared/ui'
import { MobileNewReservationVaulProcess } from '@/widgets/new-reservation'

type InitReservationButtonProps = ButtonStyleProps &
  ComponentPropsWithoutRef<'button'> & {
    setOpen?: Dispatch<SetStateAction<boolean>>
    guest?: BaseGuest
    table?: MapItem
  }

export default function InitReservationButton({
  children,
  setOpen,
  guest,
  table,
  onClick,
  ...props
}: InitReservationButtonProps) {
  const dispatch = useAppDispatch()

  const initHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (guest) {
      dispatch(setReservationSkipStep(ReservationSkipStep.GUEST))
      dispatch(setReservationGuest(guest))
    }

    if (table) {
      dispatch(setReservationSkipStep(ReservationSkipStep.TABLE))
      dispatch(setReservationTables([table]))
    }
  }

  return (
    <VaulNested dismissible={false}>
      <VaulTrigger asChild>
        <Button {...props} onClick={onClick ?? initHandler}>
          {children}
        </Button>
      </VaulTrigger>
      <VaulContent dismissible={false} fullScreen isVisibleButtonClose={false}>
        <MobileNewReservationVaulProcess setOpen={setOpen} />
      </VaulContent>
    </VaulNested>
  )
}
