import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import { nanoid } from 'nanoid'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useReservationSourceFilterQuery } from '@/entities/reservation-source'
import {
  selectSelectedAddressId,
  selectSelectedChainId
} from '@/entities/session'
import {
  useGuestTagsFilterQuery,
  useReservationTagsFilterQuery
} from '@/entities/tags'
import { PATHS } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  FilterMulti,
  FilterSingle,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea
} from '@/shared/ui'

import css from './ReservationsFiltersPopover.module.css'

const clientFilters: Filter[] = [
  // {
  //   id: nanoid(),
  //   type: 'single',
  //   label: 'Статус брони',
  //   key: 'status',
  //   options: [
  //     { value: 'confirmed', label: 'Подтверждено' },
  //     { value: 'unconfirmed', label: 'Не подтверждено' },
  //     { value: 'completed', label: 'Завершено' },
  //     { value: 'in_progress', label: 'В процессе' },
  //     { value: 'canceled', label: 'Отменено' }
  //   ]
  // },
  {
    id: nanoid(),
    type: 'multi',
    label: 'Кол-во гостей',
    key: 'persons_count',
    options: [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9+' }
    ]
  }
]

export default function ReservationsFiltersPopover() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const chainId = useAppSelector(selectSelectedChainId)
  const addressId = useAppSelector(selectSelectedAddressId)

  const { data: guestTagsFilter, isLoading: isGuestTagsFilterLoading } =
    useGuestTagsFilterQuery(chainId ? { chainId } : skipToken)

  const {
    data: reservationTagsFilter,
    isLoading: isReservationsTagsFilterLoading
  } = useReservationTagsFilterQuery(addressId ? { addressId } : skipToken)

  const { data: sourcesFilter, isLoading: isSourcesFilterLoading } =
    useReservationSourceFilterQuery(addressId ? { addressId } : skipToken)

  const disabled =
    isGuestTagsFilterLoading ||
    !guestTagsFilter ||
    isSourcesFilterLoading ||
    !sourcesFilter ||
    isReservationsTagsFilterLoading ||
    !reservationTagsFilter

  const clearFilters = () => {
    navigate(PATHS.reservations)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger asChild>
        <Button
          size="icon"
          variant={open ? 'secondary' : 'tertiary'}
          className={cx(css.trigger, {
            [css.trigger_selected]: searchParams.size > 0
          })}
          disabled={disabled}
        >
          <Icon name="settings" size={20} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={css.popover}
        sideOffset={8}
        align="end"
        variant="glass"
      >
        <div className={css.popover__header}>
          <Button variant="white" size="xs" onClick={clearFilters}>
            Сбросить
            <Icon name="x" />
          </Button>
        </div>

        {!disabled ? (
          <ScrollArea viewPortClassName={css.popover__container}>
            {[
              ...clientFilters,
              guestTagsFilter,
              reservationTagsFilter,
              sourcesFilter
            ].map((filter) =>
              filter.type === 'single' ? (
                <FilterMulti
                  key={filter.id}
                  label={filter.label}
                  options={filter.options}
                  queryKey={filter.key}
                />
              ) : (
                <FilterSingle
                  key={filter.id}
                  label={filter.label}
                  options={filter.options}
                  queryKey={filter.key}
                />
              )
            )}
          </ScrollArea>
        ) : null}
      </PopoverContent>
    </Popover>
  )
}
