/* eslint-disable boundaries/element-types */
import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import {
  useDeleteGuestCommentMutation,
  useGuestCommentsQuery,
  useSendGuestCommentMutation,
  useUpdateGuestCommentMutation
} from '@/entities/comments'
import { useUpdateGuestWithoutInvalidationMutation } from '@/entities/guest/api/guestApi'
import { MobileTagsAndComments } from '@/entities/reservation'
import { selectSelectedChainId } from '@/entities/session'
import { useGuestTagsQuery } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'

type MobileGuestTagsAndCommentsProps = {
  guest: BaseGuest
}

export default function MobileGuestTagsAndComments({
  guest
}: MobileGuestTagsAndCommentsProps) {
  const [tagsState, setTagsState] = useState<Tag[]>(guest.tags)
  const chainId = useAppSelector(selectSelectedChainId)
  const [deleteComment] = useDeleteGuestCommentMutation()
  const [sendComment] = useSendGuestCommentMutation()
  const [updateComment] = useUpdateGuestCommentMutation()
  const [updateTags] = useUpdateGuestWithoutInvalidationMutation()
  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments
  } = useGuestCommentsQuery({
    guestId: guest.id
  })
  const {
    data: tagsKit,
    isLoading: isLoadingTagsKit,
    isError: isErrorTagsKit
  } = useGuestTagsQuery(chainId ? { chainId } : skipToken, {
    refetchOnMountOrArgChange: true
  })

  const deleteCommentHandle = async (id: number) => {
    await deleteComment({ guestId: guest.id, commentId: id })
  }

  const updateCommentHandle = async (id: number, text: string) => {
    await updateComment({
      guestId: guest.id,
      commentId: id,
      body: { text }
    })
  }

  const createCommentHandle = async (text: string) => {
    await sendComment({ guestId: guest.id, body: { text } })
  }

  const setTagsHandle = async (tags: SimpleTag[]) => {
    if (!chainId) return
    try {
      const data = await updateTags({
        chainId,
        guestId: guest.id,
        body: { tags: tags.map((tag) => tag.id as string) }
      }).unwrap()
      setTagsState(data.tags)
    } catch {
      // do nothing
    }
  }

  // const createTagKitHandle = async (name: string) => {
  //   if (!addressId) return

  //   await createTag({ addressId, body: { name } })
  // }

  return (
    <MobileTagsAndComments
      tags={tagsState.map((tag) => ({
        id: tag.id,
        name: tag.tag
      }))}
      tagsKit={tagsKit?.map((tag) => ({
        id: tag.id,
        name: tag.tag
      }))}
      isErrorTagsKit={isErrorTagsKit}
      isLoadingTagsKit={isLoadingTagsKit}
      setTagsHandle={setTagsHandle}
      comments={comments}
      isLoadingComments={isLoadingComments}
      isErrorComments={isErrorComments}
      deleteCommentHandle={deleteCommentHandle}
      updateCommentHandle={updateCommentHandle}
      createCommentHandle={createCommentHandle}
    />
  )
}
