/* eslint-disable boundaries/element-types */
import {
  type Dispatch,
  type MouseEvent,
  type PropsWithChildren,
  type SetStateAction,
  useEffect,
  useState
} from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { selectSelectedChainId } from '@/entities/session'
import { useGuestTagsQuery } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  Card,
  ConfirmPhonePopup,
  Drawer,
  DrawerContent,
  DrawerTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Icon,
  Input,
  PhoneInput
} from '@/shared/ui'

import { NewGuestComments } from '../../GuestComments'
import {
  type CreateGuestFormSchema,
  createGuestFormSchema
} from '../model/createGuestFormSchema'

import css from './CreateEditGuest.module.css'

type CreateEditGuestProps = PropsWithChildren & {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  id?: string | number
  createGuest?: (args: { chainId: number; body: CreateGuestBody }) => void
  changeGuest?: (args: UpdateGuestParams) => void
  rememberNumber?: boolean
  defaultValues?: CreateGuestFormSchema
  isLoading?: boolean
}

export function CreateEditGuest({
  open,
  setOpen,
  id,
  children,
  createGuest,
  changeGuest,
  defaultValues,
  isLoading
}: CreateEditGuestProps) {
  const [comments, setComments] = useState<UserComment[]>([])
  const [tags, setTags] = useState<SimpleTag[]>([])
  const chainId = useAppSelector(selectSelectedChainId)

  const { data: tagsKit, isLoading: isLoadingTagsKit } = useGuestTagsQuery(
    chainId ? { chainId } : skipToken
  )

  const form = useForm<CreateGuestFormSchema>({
    values: defaultValues,
    resolver: zodResolver(createGuestFormSchema)
  })

  const {
    formState: { errors }
  } = form

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error(errors.phone?.message)
    }
  }, [errors])

  const submitHandler = async ({
    firstName,
    lastName,
    phone
  }: CreateGuestFormSchema) => {
    if (!chainId || !tagsKit) return

    if (createGuest) {
      const body: CreateGuestBody = {
        phone: `+${phone}`,
        first_name: firstName,
        comments: comments.map((comment) => comment.text),
        last_name: lastName ?? '',
        tags: tags.map(
          (t) => String(tagsKit.find(({ tag }) => tag === t.name)?.id) ?? ''
        )
      }
      createGuest({ chainId, body })
    }
    if (changeGuest && id) {
      const body: UpdateGuestBody = {
        phone: `+${phone}`,
        first_name: firstName,
        last_name: lastName
      }
      changeGuest({ chainId, guestId: id, body })
    }
  }

  const toggleDrawer = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  useEffect(() => {
    if (!open) {
      form.reset({
        firstName: '',
        lastName: '',
        phone: ''
      })
    }
  }, [open])

  return (
    <Drawer open={open} direction="right">
      <DrawerTrigger asChild onClick={toggleDrawer}>
        {children}
      </DrawerTrigger>
      <DrawerContent
        withCloseButton
        onClose={() => setOpen(false)}
        className={css.content}
      >
        <div className={css.header}>
          <h4 className={css.header__title}>
            {createGuest
              ? 'Заполните данные нового гостя'
              : 'Измените данные гостя'}
          </h4>
          <ConfirmPhonePopup
            sideOffset={8}
            side="bottom"
            variant="glass"
            align="end"
            alignOffset={8}
            phone={form.getValues('phone')}
            textConfirmButton={
              createGuest ? 'Верно, создать' : 'Верно, изменить'
            }
            form="guest_edit_form"
            isLoading={isLoading}
          >
            <Button
              type="button"
              variant="primary"
              className={css.button_submit}
              disabled={!form.formState.isValid}
            >
              {createGuest ? 'Создать' : 'Сохранить'}
              <Icon name="check" />
            </Button>
          </ConfirmPhonePopup>
        </div>
        <Form {...form}>
          <form
            className={css.form}
            onSubmit={form.handleSubmit(submitHandler)}
            id="guest_edit_form"
          >
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <Card className={css.card}>
                    <FormLabel className={css.label}>Имя</FormLabel>
                    <FormControl>
                      <Input
                        data-vaul-no-drag
                        variant="outlined"
                        autoFocus
                        placeholder="Введите имя гостя"
                        {...field}
                      />
                    </FormControl>
                  </Card>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <Card className={css.card}>
                    <FormLabel className={css.label}>Фамилия</FormLabel>
                    <FormControl>
                      <Input
                        data-vaul-no-drag
                        variant="outlined"
                        placeholder="Введите фамилию гостя"
                        {...field}
                      />
                    </FormControl>
                  </Card>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className={css.phone}>
                  <Card className={css.card}>
                    <FormLabel className={css.label}>Телефон</FormLabel>
                    <FormControl>
                      <PhoneInput
                        data-vaul-no-drag
                        variant="outlined"
                        value={field.value}
                        onValueChange={field.onChange}
                        placeholder="Введите телефон"
                        className={css.input}
                        classNameLabel={css.input__label}
                      />
                    </FormControl>
                  </Card>
                </FormItem>
              )}
            />
          </form>
        </Form>
        {createGuest && (
          <NewGuestComments
            comments={comments}
            setComments={setComments}
            tags={tags}
            setTags={setTags}
            tagsKit={tagsKit}
            isLoadingTagsKit={isLoadingTagsKit}
          />
        )}
      </DrawerContent>
    </Drawer>
  )
}
