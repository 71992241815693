import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import { endOfDay } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { type ItemProps, Virtuoso } from 'react-virtuoso'

import { INFINITE_SCROLL_RES } from '@/app/config'
import { ReservationCard, useReservationsQuery } from '@/entities/reservation'
import {
  changeReservationsSelectedInterval,
  selectReservationsSearch,
  selectReservationsSelectedInterval
} from '@/entities/reservations'
import { selectSelectedAddressId } from '@/entities/session'
import { useDebounce } from '@/shared/hooks'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Error, Icon } from '@/shared/ui'

import ReservationListSkeleton from './ReservationListSkeleton'
import { ReservationsSortPopover } from './ReservationsSortPopover'

import {
  ReservationsEmptyPlaceholder,
  ReservationsListFooter
} from '../../MobileReservationsList'

import css from './ReservationsList.module.css'

export function ReservationListWrapperCard(props: ItemProps<Reservation>) {
  return <div {...props} className={css['wrapper-card']} />
}

export default function ArchiveReservationsList() {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const search = useAppSelector(selectReservationsSearch)
  const address = useAppSelector(selectSelectedAddressId)
  const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
  const [page, setPage] = useState(1)
  const [disabled, setDisabled] = useState(false)
  const [ordering, setOrdering] = useState<ReservationsOrdering>({
    date: '-start_date',
    tables: 'default_1'
  })

  const debouncedSearch = useDebounce(search)
  const debouncedFilters = useDebounce(searchParams.toString())
  const [reservations, setReservations] = useState<Reservation[]>([])

  const { data, isLoading, isFetching, isError, isSuccess, refetch } =
    useReservationsQuery(
      address
        ? {
            address,
            limit: INFINITE_SCROLL_RES,
            offset: (page - 1) * INFINITE_SCROLL_RES,
            search: debouncedSearch,
            start_date: formatTimeForRequest(selectedInterval.start_date),
            end_date: selectedInterval.end_date
              ? formatTimeForRequest(endOfDay(selectedInterval.end_date))
              : '',
            ordering: ordering.date,
            status: ['canceled', 'completed'],
            ordering_places: ordering.tables,
            filters: debouncedFilters
          }
        : skipToken,
      {
        refetchOnMountOrArgChange: true
      }
    )

  useEffect(() => {
    if (
      isSuccess &&
      data &&
      !isFetching &&
      data?.count <= reservations.length
    ) {
      setReservations(data.results)
    } else if (isSuccess && data && !isFetching) {
      setReservations((prev) => [...prev, ...data.results])
    }
  }, [isSuccess, data])

  const loadMoreRows = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    setPage(1)
    setReservations([])
  }, [
    debouncedSearch,
    debouncedFilters,
    selectedInterval.start_date,
    selectedInterval.end_date,
    ordering
  ])

  const isContentLoading = (isLoading && page > 1) || (isFetching && page === 1)

  const toggleDisableSectionHandler = () => {
    setDisabled((prev) => !prev)
  }

  useEffect(() => {
    return () => {
      dispatch(
        changeReservationsSelectedInterval({
          start_date: null,
          end_date: null
        })
      )
    }
  }, [])

  const updateReservationsList = (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => {
    if (isDelete) {
      setReservations((prev) =>
        prev.filter((item) => item.id !== reservationId)
      )
    } else if (data) {
      setReservations((prev) => {
        const updatedReservations = prev.map((reservation) => {
          if (reservation.id === reservationId) {
            const { comments_count, ...rest } = data as Partial<Reservation>
            return {
              ...reservation,
              ...rest,
              comments_count: comments_count
                ? Number(comments_count)
                : reservation.comments_count
            }
          }
          return reservation
        })

        return updatedReservations
      })
    }
  }

  if (isError) {
    return <Error refetch={refetch} />
  }

  return (
    <div className={css.reservations}>
      <div className={cx(css.header, { [css.header_disabled]: disabled })}>
        <ReservationsSortPopover ordering={ordering} setOrdering={setOrdering}>
          <Button variant="transparent" className={css.header__button}>
            <Icon name="filter" />
          </Button>
        </ReservationsSortPopover>

        <h3 className={css.header__title}>Архив броней</h3>
        <Button
          variant="transparent"
          className={cx(css.header__button, css.header__button_eye)}
          onClick={toggleDisableSectionHandler}
        >
          <Icon name={disabled ? 'eye' : 'eyeOff'} />
        </Button>
      </div>
      {disabled && <div className={css.backdrop} />}
      {isContentLoading ? (
        <ReservationListSkeleton height={234} />
      ) : (
        <Virtuoso
          className={css.reservations__list}
          totalCount={reservations.length}
          defaultItemHeight={150}
          data={reservations}
          endReached={loadMoreRows}
          increaseViewportBy={200}
          components={{
            EmptyPlaceholder: ReservationsEmptyPlaceholder,
            Item: ReservationListWrapperCard,
            Footer: ReservationsListFooter
          }}
          itemContent={(_, reservation) => (
            <ReservationCard
              id={reservation.id}
              guest={reservation.guest}
              personsCount={reservation.persons_count}
              startDate={reservation.start_date}
              mainTableNumber={reservation.main_table_number}
              source={reservation.source}
              link={reservation.link}
              tags={reservation.tags}
              status={reservation.status}
              endDate={reservation.end_date}
              updateReservationsList={updateReservationsList}
              withLink
            />
          )}
        />
      )}
    </div>
  )
}
