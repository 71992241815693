/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable boundaries/element-types */
import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'
import { format, isAfter } from 'date-fns'

import { ReservationProgress } from '@/entities/reservation'
import { ReservationStatusEnum } from '@/entities/reservations'
import { formatName, formatPhoneNumberRU, nowWithTimezone } from '@/shared/lib'
import { Card } from '@/shared/ui'

import { GuestTags } from '../GuestTags'

import css from './MobileGuestWithTime.module.css'

type MobileGuestWithTimeProps = ComponentPropsWithoutRef<'div'> & {
  guest: BaseGuest
  startDate: Date
  endDate: Date | null
  status?: ReservationStatus
  isWhite?: boolean
}

export default function MobileGuestWithTime({
  guest,
  className,
  startDate,
  endDate,
  status,
  isWhite
}: MobileGuestWithTimeProps) {
  const { first_name, last_name, phone, tags } = guest

  const isInProgress = status === ReservationStatusEnum.inProgress
  const isSkipped = endDate && isAfter(nowWithTimezone(), endDate)

  return (
    <Card className={cx(css.card, className)}>
      <div className={css.card__top}>
        <div className={css.card__column}>
          <div className={css.card__header}>
            <h4
              className={cx(css.card__text, {
                [css.card__text_white]: isWhite
              })}
            >
              {last_name
                ? formatName(first_name, `${last_name.slice(0, 1)}.`)
                : first_name}
            </h4>
            <GuestTags tags={tags} />
          </div>
          {phone && (
            <span
              className={cx(css.card__text_small, {
                [css.card__text_small_white]: isWhite
              })}
            >
              {`*${formatPhoneNumberRU(phone).slice(-5)}`}
            </span>
          )}
        </div>
        <div className={css.card__column}>
          {isInProgress ? (
            <>
              <span
                className={cx(css.card__text_small, {
                  [css.card__text_small_white]: isWhite
                })}
              >
                {format(startDate, 'HH:mm')}
              </span>
              <span
                className={cx(css.card__text, {
                  [css.card__text_skipped]: isSkipped && isInProgress,
                  [css.card__text_white]: isWhite
                })}
              >
                {endDate ? format(endDate, 'HH:mm') : 'По ситуации'}
              </span>
            </>
          ) : (
            <>
              <span
                className={cx(css.card__text, {
                  [css.card__text_white]: isWhite
                })}
              >
                {format(startDate, 'HH:mm')}
              </span>
              <span
                className={cx(css.card__text_small, {
                  [css.card__text_skipped]: isSkipped && isInProgress,
                  [css.card__text_small_white]: isWhite
                })}
              >
                {endDate ? format(endDate, 'HH:mm') : 'По ситуации'}
              </span>
            </>
          )}
        </div>
      </div>
      {isInProgress && endDate && (
        <ReservationProgress
          start={startDate}
          end={endDate}
          status={status}
          isWhite={isWhite}
        />
      )}
    </Card>
  )
}
