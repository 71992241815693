/* eslint-disable boundaries/element-types */
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type MouseEventHandler
} from 'react'

import { cx } from 'class-variance-authority'
import { parseISO } from 'date-fns'

import { MobileGuestWithTime } from '@/entities/guest'
import { MobileReservationBadgeCarousel } from '@/entities/reservation/ui/MobileReservationBadgeCarousel'
import { Reservation } from '@/features/reservation'
import { ReservationRequest } from '@/features/reservation-request'
import { Card } from '@/shared/ui'

import css from './MobileReservationRequestCard.module.css'

type MobileReservationRequestCardProps = Pick<
  ComponentPropsWithoutRef<'div'>,
  'onClick'
> & {
  reservationReq: ReservationRequest
  withLink?: boolean
}

const MobileReservationRequestCard = forwardRef<
  HTMLDivElement,
  MobileReservationRequestCardProps
>(({ reservationReq, withLink = false, onClick }, ref) => {
  const {
    id,
    guest,
    persons_count,
    link,
    planned_start_dt,
    status,
    planned_end_dt
  } = reservationReq

  const onClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    if (withLink) {
      return
    }

    onClick?.(e)
  }

  return (
    <Card ref={ref} className={css.card} onClick={onClickHandler}>
      <div className={css.container}>
        <MobileGuestWithTime
          className={css.card__guest}
          guest={guest}
          startDate={parseISO(planned_start_dt)}
          endDate={planned_end_dt ? parseISO(planned_end_dt) : null}
          status={status}
        />
        <div className={css.card__line} />
      </div>
      <MobileReservationBadgeCarousel
        className={css.card__badges}
        personsCount={persons_count}
        source="widget"
        linkName={link?.name ?? null}
        startDate={parseISO(planned_start_dt)}
      />
      <div className={css.container}>
        <div
          className={cx(css.card__actions, {
            [css.card__actions_phone]: guest.phone
          })}
        >
          <Reservation.Phone
            className={css.button_phone}
            size="icon"
            variant="tertiary"
            phone={guest.phone}
          />
          <ReservationRequest.SitGuest
            variant="tertiary"
            className={cx(css.button, css.button_sit)}
            id={id}
            withLink={withLink}
          />
          {/* TODO: */}
        </div>
      </div>
    </Card>
  )
})

MobileReservationRequestCard.displayName = 'MobileReservationRequestCard'

export default MobileReservationRequestCard
